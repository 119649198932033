
export class Tools {

    static updateQueryStringParameter(uri, key, value) {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
          return uri.replace(re, '$1' + key + "=" + value + '$2');
        }
        else {
          return uri + separator + key + "=" + value;
        }
      }
  

    static removeURLParameter(url, parameter) {
        //prefer to use l.search if you have a location/link object
        var urlparts = url.split('?');   
        if (urlparts.length >= 2) {
    
            var prefix = encodeURIComponent(parameter) + '=';
            var pars = urlparts[1].split(/[&;]/g);
    
            //reverse iteration as may be destructive
            for (var i = pars.length; i-- > 0;) {    
                //idiom for string.startsWith
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {  
                    pars.splice(i, 1);
                }
            }
    
            return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        }
        return url;
    }


    static getQueryString(ji) {
        const hu = window.location.search.substring(1);
        const gy = hu.split("&");
        for (let i = 0; i < gy.length; i++) {
            const ft = gy[i].split("=");
            if (ft[0] == ji) {
                return ft[1];
            }
        }
    }

    static getFormatedDate(d) {
        var dateStr = padLeft(d.getFullYear()) +
            "-" +
            padLeft(1 + d.getMonth()) +
            "-" +
            padLeft(d.getDate());
        return dateStr;
    }

    static setCookie(name, value, daysToExpire) {
        var exp = new Date();
        exp = new Date(exp.getTime() + 1000 * 60 * 60 * 24 * daysToExpire);
        document.cookie = name + "=" + value + "; expires=" + exp.toUTCString() + ";Domain=" + window.location.hostname + ";Secured;path=/;";
    }

    static getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        return parts.pop().split(";").shift();
    }

    static getLanguage() {
        let queryStringLanguage = Tools.getQueryString('lang');
        if (!queryStringLanguage) {
           queryStringLanguage = Tools.getQueryString('language');
        }
        if (queryStringLanguage) {
           localStorage.setItem('$dmslanguage', queryStringLanguage);
        }
        let language = localStorage.getItem('$dmslanguage');
        if (!language) {
           language = 'en';
        }
        return language;
    }
}


