import { RestAPI } from './dms-api';
import { Tools } from './dms-tools'
const jwtDecode = require('jwt-decode');

export const authAccessTokenCookie = "dmc-identity-accessv2";
export const authIdTokenCookie = "dmc-identity-idv2";
export const localHostRootUrl = "http://localhost:3001/index.html";
export const awsCognitoClientIdDEV = "7pjkrtoubjess5k9s26d1tsrv"
export const awsCognitoClientIdPROD = "1dsi4il7ij4n08bhc24h7vti6l"

export const devWebsiteDns = 'devdynamicsmobile.com';
export const prodWebsiteDns = 'dynamicsmobile.com';

export class Auth {

    static clear() {
        Tools.setCookie(authAccessTokenCookie, '', -1);
        Tools.setCookie(authIdTokenCookie, '', -1);
    }

    static getCurrentUserIdentity() {
        //const access = Tools.getCookie(authAccessTokenCookie);
        //if(!access)
        //    return false;            
        const identity = Tools.getCookie(authIdTokenCookie);
        if (!identity)
            return false;
        try {
            let decoded = jwtDecode(identity);
            let appAreaOverride = Tools.getQueryString('appArea');
            if (appAreaOverride) {
                decoded.currentAppArea = appAreaOverride;
                decoded.appArea = appAreaOverride;
            }
            else {
                decoded.currentAppArea = decoded.appArea;
            }
            return decoded;
        }
        catch (ex) {
            return null;
        }
    }

    static isAuthenticated() {
        const access = Tools.getCookie(authAccessTokenCookie);
        if (!access)
            return false;
        const identity = Tools.getCookie(authIdTokenCookie);
        if (!identity)
            return false;
        try {
            let decoded = jwtDecode(access);
            decoded = jwtDecode(identity);
            return true;
        }
        catch (ex) {
            return false;
        }

    }

    static obtainAccessTokenFromCookie() {
        return Tools.getCookie(authAccessTokenCookie)
    }

    static obtainIdTokenFromCookie() {
        return Tools.getCookie(authIdTokenCookie);
    }

    static obtainAccessTokenFromUrl() {
        let results = new RegExp('[\?&#]access_token=([^&#]*)').exec(location.href);
        if (results && results.length > 0)
            return results[1];
        else
            return null;

        var decoded = jwtDecode(token);

    }

    static obtainIdTokenFromUrl() {
        let results = new RegExp('[\?&#]id_token=([^&#]*)').exec(location.href);
        if (results && results.length > 0)
            return results[1];
        else
            return null;
    }

    static completeAuthentication() {
        const appArea = $('#app_arealist').val();
        if (!appArea) {
            alert('Please provide application area!');
            return;
        }
        const access_token = Auth.obtainAccessTokenFromUrl();
        const id_token = Auth.obtainIdTokenFromUrl();
        if (access_token) {
            Tools.setCookie(authAccessTokenCookie, access_token, 1);
            Tools.setCookie(authIdTokenCookie, id_token, 1);

            const lastUrlBeforePreAuth = localStorage.getItem('$dmspreauthredirect');
            if (lastUrlBeforePreAuth && lastUrlBeforePreAuth.indexOf(`=${appArea}`) > 0) {
                localStorage.removeItem('$dmspreauthredirect');
                window.location.replace(lastUrlBeforePreAuth)
                return;
            }
            else {
                if (window.location.href.indexOf('http://localhost') == 0) {
                    window.location.replace(localHostRootUrl + `?appArea=${appArea}`);
                }
                else {
                    const newUrl = `https://${window.location.host}/index.html?appArea=${appArea}`;
                    window.location.replace(newUrl);
                }
            }
        } else {
            $('#btnContinue').hide();
            Auth.authenticateWithCognito();
            return;
        }
    }

    static authenticateWithCognito() {
        debugger;
        localStorage.setItem('$dmspreauthredirect', window.location.href)
        let appArea;
        if(window.location.href.indexOf('.portal2')>0 || window.location.href.indexOf('.cloud2')>0 || window.location.href.indexOf('localhost')>0){
             appArea = Tools.getQueryString('appArea');
        }
        else{
            const segments = window.location.href.split('.');
            if (segments.length > 1) {
                appArea = segments[0];
            }
        }
        
        if(!appArea){
                appArea='AD';
        }
        Auth.clear();
        let redirectUrl;
        let awsCognitoClientId;
        let domain = (window.location.href.indexOf('http://localhost') == 0) ? prodWebsiteDns : (window.location.href.split('.')[1] + '.com');

        RestAPI.getAuthClientDetails(appArea, (error, authDetials) => {
            let awsCognitoClientId = authDetials.appClientId ? authDetials.appClientId : awsCognitoClientIdPROD;
            const awsLoginUrl = authDetials.awsLoginUrl ? authDetials.awsLoginUrl : 'auth.dynamicsmobile.com/login';
            if (window.location.href.indexOf('http://localhost') >= 0) {
                domain = prodWebsiteDns;
                redirectUrl = 'http://localhost:3001/loginsuccess.html'
            }
            else
                if (window.location.href.indexOf('https://portal2') >= 0) {
                    domain = `${prodWebsiteDns}`;
                    //redirect url is towards the root dns
                    redirectUrl = `https://portal2.${domain}/loginsuccess.html`
                }
                if (window.location.href.indexOf('https://cloud2') >= 0) {
                    domain = `${prodWebsiteDns}`;
                    //redirect url is towards the root dns
                    redirectUrl = `https://cloud2.${domain}/loginsuccess.html`
                }
                else
                    if (window.location.href.indexOf('.portal2') >= 0) {
                        let pureHref = window.location.href.replace('https://', '').replace('http://', '')
                        appArea = pureHref.split('.')[0];
                        domain = `${prodWebsiteDns}`;
                        //redirect url is towards the root dns
                        redirectUrl = `https://portal2.${domain}/loginsuccess.html`
                    }
                    else
                        if (window.location.href.indexOf('https://cloud2') >= 0) {
                            appArea = pureHref.split('.')[0];
                            domain = `${devWebsiteDns}`;
                            awsCognitoClientId = awsCognitoClientIdDEV;
                            //redirect url is towards the root dns
                            redirectUrl = `https://cloud2.${domain}/loginsuccess.html`
                        }
                        else
                            if (window.location.href.indexOf('.cloud2') >= 0) {
                                let pureHref = window.location.href.replace('https://', '').replace('http://', '')
                                appArea = pureHref.split('.')[0];
                                domain = `${devWebsiteDns}`;
                                awsCognitoClientId = awsCognitoClientIdDEV;
                                //redirect url is towards the root dns
                                redirectUrl = `https://cloud2.${domain}/loginsuccess.html`
                            }
                            else {
                                let pureHref = window.location.href.replace('https://', '').replace('http://', '').split('/')[0];
                                awsCognitoClientId = awsCognitoClientIdPROD;
                                //redirect url is towards the root dns
                                redirectUrl = `https://portal2.${prodWebsiteDns}/loginsuccess.html`
                            }

            const url = `https://${awsLoginUrl}?client_id=${awsCognitoClientId}&response_type=token&redirect_uri=${redirectUrl}${appArea ? '&state=' + appArea : ''}`;
            window.location.replace(url);
        });

    }
}
