import { Auth, prodWebsiteDns, devWebsiteDns } from './dms-auth';
const defaultAPIKey = 'nHgmMPTlOS9hTUcp0085j4mjXO7ttTGT4j81K8Z1';
import { Tools } from './dms-tools'
export class RestAPI {
    static initCoreApi(callback) {

        if (window.location.href.indexOf('localhost') >= 0) {
            //RestAPI.baseAPIUrl = `https://eu.api.portal.${prodWebsiteDns}`;
            // RestAPI.baseAPIUrl = `https://api.portal.${prodWebsiteDns}`;
            RestAPI.baseAPIUrl = 'https://api.cloud.devdynamicsmobile.com';
            RestAPI.__SANDBOX = false;
            if (callback)
                return callback();
        } else {
            if (window.location.hostname.indexOf('sandbox') == 0) {
                RestAPI.__SANDBOX = true;
                //RestAPI.baseAPIUrl = "https://eu.api." + window.location.hostname.replace('sandbox.', '');
                RestAPI.baseAPIUrl = "https://api." + window.location.hostname.replace('sandbox.', '');
            } else {
                RestAPI.__SANDBOX = false
                if (window.location.hostname.indexOf('devWebsiteDns') > 0) {
                    RestAPI.baseAPIUrl = `https://api.cloud.${devWebsiteDns}`;
                }
                else {
                    RestAPI.baseAPIUrl = `https://api.portal.${prodWebsiteDns}`;
                }
            }
            RestAPI.baseAPIUrl = RestAPI.baseAPIUrl.replace('cloud2', 'cloud');
            RestAPI.baseAPIUrl = RestAPI.baseAPIUrl.replace('portal2', 'portal');

            RestAPI.baseAPIUrl = 'https://api.cloud.devdynamicsmobile.com';

            if (callback)
                return callback();
        }

    }

    static handleRestApiError(error) {
        console.log('Rest API Call Error', error);
        const _errorMessage = (error.responseText ? error.responseText : error);
        var uiError = {
            message: error
        };
        if (typeof (_errorMessage) === 'string') {
            try {
                uiError = JSON.parse(_errorMessage);
            } catch (jsonError) {

            }
        } else {
            uiError = {
                message: "We can not contact the server at this time! Please check your internet connection and try again."
            };
        }


        var msg = (uiError && uiError.message) ? uiError.message : uiError;
        //$dms.uiManager.showError('OOPS..ERROR: ' + msg);
        alert(msg)

    }

    static getAppAreaMenuItems(callback) {
        RestAPI.initCoreApi();
        
        var lng = Tools.getLanguage();
        var accessToken = Auth.obtainAccessTokenFromCookie();
        var identity = Auth.getCurrentUserIdentity();

        var menuStr = sessionStorage.getItem('$dmsrootmenucache' + identity.currentAppArea);
        if (menuStr) {
            const menuWrapper = JSON.parse(menuStr);
            if (menuWrapper.lastSync - (new Date()).getTime() < 1000 * 60 * 5) {
                callback(null, JSON.parse(menuWrapper.menu));
                return;
            }
        }

        $.ajax({
            type: "GET",
            headers: {
                "Authorization": `apparea:${identity.appArea};${accessToken}`
            },
            url: RestAPI.baseAPIUrl + "/core/ui/menu/" + lng + (RestAPI.__SANDBOX ? "?dev=true" : ""),
            contentType: "application/json",
            statusCode: {
                0: function () {
                    Auth.authenticateWithCognito();
                },
                400: function (err) {
                    RestAPI.handleRestApiError(err);
                },
                403: function (err) {
                    Auth.authenticateWithCognito()
                },
                404: function (err) {
                    RestAPI.handleRestApiError(err);
                },
                407: function (err) {
                    Auth.authenticateWithCognito()
                },
                500: function (err) {
                    RestAPI.handleRestApiError(err);
                },
                200: function (data) {
                    sessionStorage.setItem('$dmsrootmenucache' + identity.currentAppArea, JSON.stringify({ lastSync: (new Date()).getTime(), menu: JSON.stringify(data) }))
                    callback(null, data);
                }
            }
        });
    }


    static getInstalledApps(callback) {
        
        RestAPI.initCoreApi();
        var lng = "en";
        var accessToken = Auth.obtainAccessTokenFromCookie();
        var identity = Auth.getCurrentUserIdentity();

        $.ajax({
            type: "GET",
            headers: {
                "Authorization": `apparea:${identity.appArea};${accessToken}`
            },
            url: `${RestAPI.baseAPIUrl}/storage/sysentity/App`,
            contentType: "application/json",
            statusCode: {
                0: function () {
                    Auth.authenticateWithCognito();
                },
                400: function (err) {
                    RestAPI.handleRestApiError(err);
                },
                403: function (err) {
                    Auth.authenticateWithCognito()
                },
                404: function (err) {
                    RestAPI.handleRestApiError(err);
                },
                407: function (err) {
                    Auth.authenticateWithCognito()
                },
                500: function (err) {
                    RestAPI.handleRestApiError(err);
                },
                200: function (data) {                   
                    callback(null, data);
                }
            }
        });
    }

    static getAuthClientDetails(appArea, callback) {        
        RestAPI.initCoreApi();
        $.ajax({
            type: "GET",
            headers: {
                "x-api-key": `${defaultAPIKey}`
            },
            url: `${RestAPI.baseAPIUrl}/core/security/ticket?appArea=${appArea}&version=website`,
            contentType: "application/json",
            statusCode: {
                0: function () {
                    RestAPI.handleRestApiError();
                },
                400: function (err) {
                    RestAPI.handleRestApiError(err);
                },
                403: function (err) { 
                    Auth.authenticateWithCognito()
                },
                404: function (err) {
                    RestAPI.handleRestApiError(err);
                },
                407: function (err) {
                    Auth.authenticateWithCognito()
                },
                500: function (err) {
                    RestAPI.handleRestApiError(err);
                },
                200: function (data) {                   
                    callback(null, data);
                }
            }
        });
    }

    static checkMarketplaceForPendingapps(callback) {
        RestAPI.initCoreApi();
        var accessToken = Auth.obtainAccessTokenFromCookie();
        var identity = Auth.getCurrentUserIdentity();
        $.ajax({
            type: "GET",
            headers: {
                "Authorization": `apparea:${identity.appArea};${accessToken}`
            },
            url: `${RestAPI.baseAPIUrl}/marketplace/all?appArea=${identity.appArea}`,
            contentType: "application/json",
            statusCode: {
                0: function () {
                    Auth.authenticateWithCognito();
                },
                400: function (err) {
                    RestAPI.handleRestApiError(err);
                },
                403: function (err) {
                    Auth.authenticateWithCognito()
                },
                404: function (err) {
                    RestAPI.handleRestApiError(err);
                },
                407: function (err) {
                    Auth.authenticateWithCognito()
                },
                500: function (err) {
                    RestAPI.handleRestApiError(err);
                },
                200: function (data) {                   
                    callback(null, data);
                }
            }
        });
    }

    static changeUserPassword(code, user, newPassword, callback) {
        RestAPI.initCoreApi();
        var url = `/core/security/change?email=${user}&apparea=2&lostToken=${code}&newPassword=${encodeURIComponent(newPassword)}`;

        $.ajax({
            type: "POST",
            url: RestAPI.baseAPIUrl + url,
            contentType: "application/json",
            statusCode: {
                0: function (err) {
                    callback({ success: false, error: err })
                },
                400: function (err) {
                    callback({ success: false, error: err })
                },
                403: function (err) {
                    callback({ success: false, error: err })
                },
                404: function (err) {
                    callback({ success: false, error: err })
                },
                407: function (err) {
                    callback({ success: false, error: err })
                },
                500: function (err) {
                    callback({ success: false, error: err })
                },
                200: function (data) {
                    callback(data)
                }
            }
        });
    }


    static checkUserInbox(callback){
        const err = null;
        RestAPI.initCoreApi();
        var accessToken = Auth.obtainAccessTokenFromCookie();
        var identity = Auth.getCurrentUserIdentity();
        $.ajax({
            type: "GET",
            headers: {
                "Authorization": `apparea:${identity.appArea};${accessToken}`
            },
            url: `${RestAPI.baseAPIUrl}/storage/entity/ALRT/Inbox?isSandBox=false&$filter=toUser eq '${identity['cognito:username']}' and status ne 1`,
            contentType: "application/json",
            statusCode: {
                0: function () {
                    callback(null,0);
                },
                400: function (err) {
                    callback(null,0);
                },
                403: function (err) {
                    callback(null,0);
                },
                404: function (err) {
                    callback(null,0);
                },
                407: function (err) {
                    callback(null,0);
                },
                500: function (err) {
                    callback(null,0);
                },
                200: function (data) {      
                    if(data && data.value && data.value.length>0){
                        callback(null, data.value);
                    }
                    else {
                        callback(null, []);
                    }
                }
            }
        });
    }


}