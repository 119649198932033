import { Auth, devWebsiteDns, prodWebsiteDns } from './dms-auth';
import { Tools } from './dms-tools'
import { RestAPI } from './dms-api';
const $ = require('jquery');
import { sidebarMenu } from './sidebar-menu.js';
import './sidebar-menu.css';
import '../sass/app.scss';
const bt = require('bootstrap');
import '../css/bootstrap.min.css';
import '../css/fontawesome.min.css';
import { set } from 'lodash';
let jwtDecode = require('jwt-decode');
let globalNavigationMenuItems = [];
let currentApp;
const defaultAppCode = 'ADM';
import 'bootstrap';

function renderMenuItem(appMenuObject, currentItem, uiItems) {
   const identity = Auth.getCurrentUserIdentity();
   const appAreaOverride = identity.currentAppArea ? `&appArea=${identity.currentAppArea}` : `&appArea=${identity.appArea}`
   const executingTask = Tools.getQueryString('task');
   const isCurrentTaskActive = (executingTask && currentItem.taskId && currentItem.taskId == executingTask) ? true : false;

   if (isCurrentTaskActive) {
      //this is the selected menu item
      uiItems.push(`<li class="active">`);
   }
   else {
      //regular menu item , e.g. not selected
      uiItems.push(`<li>`);

   }

   if (currentItem && currentItem.items && currentItem.items.length > 0) {
      //expandable item
      uiItems.push(`<a data-dms-task="${currentItem.taskId ? currentItem.taskId : 'Home'}" href="#"><i class="fa ${currentItem.icon ? currentItem.icon : ''}"></i>${currentItem.label}<i class="fa fa-angle-right m-2 pull-right"></i></a>`);
      uiItems.push(`<ul class="sidebar-submenu" style="display: none;">`);
      currentItem.items.forEach(item => {
         renderMenuItem(appMenuObject, item, uiItems);
      });
      uiItems.push(`</ul>`);
   } else
      if (currentItem && currentItem.taskId) {
         uiItems.push(`<a data-dms-task="${currentItem.taskId ? currentItem.taskId : 'Home'}" class="item" href="/index.html?app=${appMenuObject.code}&task=${currentItem.taskId}${appAreaOverride}"><i class="fa ${currentItem.icon ? currentItem.icon : ''}"></i>${currentItem.label}</a>`)
         //render breadcrumb
         if (isCurrentTaskActive) {
            $('.breathercrumb>ul').html(`<li><span>${appMenuObject.label} / ${currentItem.label}</span></li>`);
         }
      }
      else {
         // uiItems.push(`<a href="">${currentItem.label}</a>`)

      }
   uiItems.push(`</li>`)
}

function renderApplication() {
   //clear all navigations

   $('.breathercrumb>ul').html('');
   $('.quick-menu>.menu>ul').html('');
   const identity = Auth.getCurrentUserIdentity();
   $('#identity').html(`${identity.name}@${identity.currentAppArea}`)

   if (!currentApp)
      return;

   $('#homeLink').attr('href', `/index.html?app=${currentApp.code}&task=Home&appArea=${identity.currentAppArea ? identity.currentAppArea : identity.appArea}`)
   if (!currentApp) {
      window.location.assign(`/index.html?app=${currentApp.code}&task=Home&appArea=${identity.currentAppArea ? identity.currentAppArea : identity.appArea}`)
      return;
   }
   //render breadcrumb
   $('.breathercrumb>ul').html(`<li><a href="#" data-dms-app="${currentApp.code}">${currentApp.label}</a></li>`);

   //render main menu
   const mainAppMenu = [];


   if (currentApp.items) {
      currentApp.items.forEach(item => {
         renderMenuItem(currentApp, item, mainAppMenu);
      });
   }
   if (window.location.href.indexOf('/index.html') > 0) {
      //apply the menu markup
      $('.main-nav>ul').html(mainAppMenu.join(' '));

      //Highlight active menu item
      //TODO: implement recursive algorithm
      //sub-level 1
      $('.main-nav ul>li.active').parent().addClass('menu-open').css('display', 'block').parent().addClass('active');
      //sub-level 2
      $('.main-nav ul>li.active').parent().parent().parent().addClass('menu-open').css('display', 'block').parent().addClass('active');
      //sub-level 3
      $('.main-nav ul>li.active').parent().parent().parent().parent().parent().addClass('menu-open').css('display', 'block').parent().addClass('active');
      sidebarMenu($('.sidebar-menu'));
   }

   //render quick menu
   const quickMenuItems = [];
   if (currentApp && !currentApp.quickItems || currentApp.quickItems.length == 0) {
      currentApp.quickItems = [];
   };
   if (currentApp && currentApp.quickItems) {
      currentApp.quickItems.forEach(q => {
         quickMenuItems.push(`<li><a href="/index.html?app=${currentApp.code}&task=${q.taskId}&appArea=${identity.currentAppArea ? identity.currentAppArea : identity.appArea}">${q.label}</a></li>`);
      });
   }
   if (quickMenuItems) {
      $('.quick-menu>.menu>ul').html(quickMenuItems.join(' '));
   }
   $('#profileLink').attr('href', `/index.html?app=ADM&task=Profile&appArea=${identity.currentAppArea ? identity.currentAppArea : identity.appArea}`)
}

function provisionHostMenus() {
   globalNavigationMenuItems = [];
   const theme = localStorage.getItem('$dmstheme');
   switch (theme) {
      case '0': $('body').removeClass('is-dark'); break;
      case '1': $('body').addClass('is-dark'); break;
      default: $('body').removeClass('is-dark'); break;
   }

   if (window.location.href.indexOf('/index.html') > 0) {
      $('.apps-grid').html('');
      $('.admin-menu').html('');
      $('.quick-menu>.menu>ul').html('');
      $('.breathercrumb>ul').html('');
      $('.main-nav>ul').html('');
      $('#hostFrame').on('load', (ev) => {
         if (ev && ev.target && ev.target.contentDocument && ev.target.contentDocument.documentElement) {
            const html = $(ev.target.contentDocument.documentElement).html();
            if (html && (html.indexOf('<Code>AccessDenied</Code>') == 0 && html.indexOf('<Message>Access Denied</Message>') > 0)) {
               $('#refreshaccessdenied').show();
               return;
            }
            const title = $(ev.target.contentDocument.documentElement).find('title');
            if (title && title.html() && title.html().toLocaleLowerCase() == 'error') {
               $('#refreshaccessdenied').show();
               return;
            }
         }
      })
   }

   $('#btnTheme').on('click', () => {
      const theme = localStorage.getItem('$dmstheme');
      switch (theme) {
         case '0': localStorage.setItem('$dmstheme', '1'); break;
         case '1': localStorage.setItem('$dmstheme', '0'); break;
         default: localStorage.setItem('$dmstheme', '0'); break;
      }
      window.location.reload();
   });

   RestAPI.getAppAreaMenuItems((err, items) => {


      const identity = Auth.getCurrentUserIdentity();
      if (!identity)
         return;
      var language = Tools.getLanguage();


      let taskName = Tools.getQueryString('task');
      let appCode = Tools.getQueryString('app');
      if ((!taskName || !appCode) && (window.location.href.indexOf('/index.html') > 0 || window.location.href.indexOf('.html') < 0)) {
         taskName = Tools.getQueryString('task') ? Tools.getQueryString('task') : 'Home';
         appCode = Tools.getQueryString('app') ? Tools.getQueryString('app') : (items && items.length ? items[0].roleDefaultApp : defaultAppCode);
         window.location.assign(`/index.html?app=${appCode}&task=${taskName}&appArea=${identity.currentAppArea}`)
         return;
      };

      globalNavigationMenuItems = items;
      const customApps = [];
      const sysApps = [];
      if (globalNavigationMenuItems) {
         globalNavigationMenuItems.forEach(app => {
            if (app.type == 'SYS' && app.code != 'MRKT') {
               sysApps.push(`<li style="margin:10px;"><a class="sys-app-item" href="/index.html?app=${app.code}&task=Home&appArea=${identity.currentAppArea ? identity.currentAppArea : identity.appArea}">${app.icon ? `<img src="${app.icon}" class="icon"></img>` : ''}<span style="display:block">${app.label}</span></a></li>`);
            }
            else
               if (app.type != 'SYS') {
                  customApps.push(`<li><a href="/index.html?app=${app.code}&task=Home&appArea=${identity.currentAppArea ? identity.currentAppArea : identity.appArea}">
               ${app.icon ? `<object type="image/svg+xml" data="${app.icon}"class="icon"></object>` : ''} <span>${app.label}</span></a></li>`);
               }
         });
      }
      //add Add-Application button
      customApps.push(`<li>
      <span class="appupdatedpending badge badge-warning" style="display: none;position:relative;top:-16px;left:12px;margin:0;z-index:9999">1</span>     
      <a class="add-more" href="/index.html?app=MRKT&task=Home&appArea=${identity.currentAppArea ? identity.currentAppArea : identity.appArea}">
         <img src="/assets/images/apps-add-btn.svg" alt="">
      </a>
      </li>`)
      //custom applications
      $('.apps-grid').html(customApps.join(' '));
      //system apps
      $('.admin-menu').html(sysApps.join(' '));
      //quick menu
      $('.quick-menu>.menu>ul').html('');



      if (globalNavigationMenuItems && globalNavigationMenuItems.length > 0) {
         currentApp = globalNavigationMenuItems.find(app => app.code == appCode);
         if (!currentApp) {
            currentApp = globalNavigationMenuItems[0];
            window.location.assign(`/index.html?app=${currentApp.code}&task=${taskName}&appArea=${identity.currentAppArea}`)
            return;
         }
      }
      else {
         currentApp = {
            version: '1',
            type: 'SYS',
            appCode: 'ADM'
         };
      }
      renderApplication();

      let appArea = identity.appArea;
      let originalAppArea = identity.appArea;
      let appVersion = `-${currentApp.version}`;
      document.title = `${currentApp.code} - ${appArea}`;
      $('#manifest').attr('href', `/user/apparea/${appArea}/manifest.json`)
      const isLocalhost = window.location.href.indexOf('http://localhost') == 0;
      if (isLocalhost) {
         appArea = 'SANDBOX';
         appVersion = '';
      }
      if (currentApp.icon) {
         $('.navbar-brand>img').attr('src', currentApp.icon).show();
      }
      else {
         $('.navbar-brand>img').attr('src', '/assets/images/small-logo.svg').show();
      }
      if (isLocalhost) {
         $('#hostFrame').attr('src', `user/apparea/${appArea}/${isLocalhost ? 'APP' : currentApp.code}${appVersion}/${language}/${taskName}.html?noState=true&appArea=${originalAppArea}`);
      }
      else {
         if (currentApp && currentApp.type == 'SYS') {
            $('#hostFrame').attr('src', `user/system/${currentApp.code}/${language}/${taskName}.html?noState=true&appArea=${originalAppArea}`);
         }
         else {
            $('#hostFrame').attr('src', `user/apparea/${appArea}/${isLocalhost ? 'APP' : currentApp.code}${appVersion}/${language}/${taskName}.html?noState=true&appArea=${originalAppArea}`);
         }
      }

      RestAPI.checkMarketplaceForPendingapps((err1, marketplaceApps) => {
         RestAPI.getInstalledApps((err2, installedApps) => {
            let appsForUpdate = [];
            if (marketplaceApps && installedApps && !err1 && !err2) {
               marketplaceApps.forEach(mApp => {

                  const iApp = installedApps.find(iApp => iApp.appCode === mApp.appCode && iApp.source === 'M');
                  if (iApp && mApp.update === true && mApp.installed === true) {
                     appsForUpdate.push(mApp);
                  }
               });
            }
            if (appsForUpdate && appsForUpdate.length > 0) {
               $('.appupdatedpending').show();
               $('.appupdatedpending').html(appsForUpdate.length);
            }
            else {
               $('.appupdatedpending').hide();
            }
         });
      });


      //$('.toast-inbox-alert').attr('href',`/index.html?app=ADM&task=Profile&appArea=${identity.currentAppArea ? identity.currentAppArea : identity.appArea}`)
      //$('.inboxalerttoast').toast({animation: true,   autohide: true, delay: 10000});

      RestAPI.checkUserInbox((err, messages) => {
         if (messages.length > 0) {
            $('.userinboxbadge').show();
               $('.userinboxbadge').html(messages.length);
               let isThereOneOrMoreSystemMessages = false;
               messages.forEach(m => {
                  if (m.fromUser == '$system-important') {
                     isThereOneOrMoreSystemMessages = true;
                  }
               }
               );
               if (isThereOneOrMoreSystemMessages) {
                  //$('.importantalerttoast').toast({ animation: true, autohide: true, delay: 10000 });

                  const importantalerttoast = document.getElementById('importantalerttoast')
                  const toastBootstrap = bootstrap.Toast.getOrCreateInstance(importantalerttoast)
                  toastBootstrap.show();
               }
         }
         else {
            $('.userinboxbadge').hide();
         }
      })
      setInterval(() => {
         RestAPI.checkUserInbox((err, messages) => {
            if (messages.length > 0) {
               $('.userinboxbadge').show();
               $('.userinboxbadge').html(messages.length);
               let isThereOneOrMoreSystemMessages = false;
               messages.forEach(m => {
                  if (m.fromUser == '$system-important') {
                     isThereOneOrMoreSystemMessages = true;
                  }
               }
               );
               if (isThereOneOrMoreSystemMessages) {
                  //$('.importantalerttoast').toast({ animation: true, autohide: true, delay: 10000 });

                  const importantalerttoast = document.getElementById('importantalerttoast')
                  const toastBootstrap = bootstrap.Toast.getOrCreateInstance(importantalerttoast)
                  toastBootstrap.show();
               }
            }
            else {
               $('.userinboxbadge').hide();
            }
         })
      }, 1000 * 60 * 3);
   })
}

function getPWADisplayMode() {
   const appInstalled = localStorage.getItem('appInstalled')

   if (appInstalled == 'true')
      return 'standalone';

   const isStandalone = window.matchMedia('(display-mode: standalone)').matches;


   if (document.referrer.startsWith('android-app://')) {
      return 'twa';
   } else if (navigator.standalone || isStandalone) {
      return 'standalone';
   }


   return 'browser';
}

function showInstallPromotion() {
   if (localStorage.getItem('supressinstallpromotion') != 'true') {
      $('#installPromotion').show();
   }
}

function hideInstallPromotion() {
   $('#installPromotion').hide();
}

// Initialize deferredPrompt for use later to show browser install prompt.
let deferredPrompt;

window.addEventListener('beforeinstallprompt', (e) => {
   // Prevent the mini-infobar from appearing on mobile
   e.preventDefault();
   // Stash the event so it can be triggered later.
   deferredPrompt = e;
   // Update UI notify the user they can install the PWA
   showInstallPromotion();
});

window.addEventListener('appinstalled', () => {
   // Hide the app-provided install promotion
   hideInstallPromotion();
   // Clear the deferredPrompt so it can be garbage collected
   deferredPrompt = null;
});

function registerServiceWorker() {
   if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js')
         .then(function (registration) {
            window.pwa = registration;
         })
         .catch(function (error) {
            //console.log('Service worker registration failed, error:', error);
         });
   }


   var page_start_time = performance.now();
   if (navigator && navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', function () {
         // Prompt the user to refresh instead
         $('#refreshpwa').show();
      }
      );
   }
   var nhours = 1 * 60 * 60 * 1000
   setInterval(() => {
      if (window.pwa && window.pwa.update) {
         //console.log('new pwa detected...updating')
         window.pwa.update();
      }
   }, nhours);
}

export function initApplicationHost() {


   $('#supressinstallpromotion').on('click', () => {
      hideInstallPromotion();
      localStorage.setItem('supressinstallpromotion', 'true');
   });

   $('#btnInstall').on('click', async () => {
      // Hide the app provided install promotion
      hideInstallPromotion();

      // Show the install prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      const { outcome } = await deferredPrompt.userChoice;
      localStorage.setItem('appInstalled', 'true');
      // Optionally, send analytics event with outcome of user choice
      //console.log(`User response to the install prompt: ${outcome}`);
      // We've used the prompt, and can't use it again, throw it away
      deferredPrompt = null;
   });



   if (window.location.href.indexOf('/imenroll.html') > 0) {

      //http://localhost:4000/imenroll.html?param=ewoidXJsIjoidmliZXI6Ly9wYT9jaGF0VVJJPWR5bmFtaWNzbW9pYmxlLWRlbW8tY2hhdGJvdCIsCiJjb250ZXh0IjoiJTJCMzU5ODc3MTUxNDAyIiwKInRleHQiOiIxMjMiLAoiY2hhbm5lbCI6InZpYmVyIiwKInRpdGxlIjoiRHluYW1pY3MlMjBNb2JpbGUlMjBDaGF0Ym90Igp9

      // {
      //    "url":"viber://pa?chatURI=dynamicsmoible-demo-chatbot",
      //    "context":"%2B359877151402",
      //    "text":"123",
      //    "channel":"viber",
      //    "title":"Dynamics%20Mobile%20Chatbot"
      //    }

      // $('#consentwrapper').click(() => {
      //    const checkBox = document.getElementById('consent');
      //    checkBox.checked = !checkBox.checked;
      // })
      // $('#enrollurl').click(function (event) {
      //    const checkBox = document.getElementById('consent');
      //    // Check if the element is selected/checked
      //    if (!checkBox.checked) {
      //       event.preventDefault();
      //       alert('You have to agree to receive notifications, first');
      //       $('#consentwrapper').stop().css("background-color", "#FFFF9C").animate({ backgroundColor: "#FFFFFF" }, 1500).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100);
      //       return false;
      //    }
      //    else {
      //       return true;
      //    }
      // });

      // const param = Tools.getQueryString('param');
      // const decoded = atob(param);
      // const parsed = JSON.parse(decoded);
      // const phone = decodeURIComponent(parsed.context);
      // const url = decodeURIComponent(parsed.url);
      // const channel = decodeURIComponent(parsed.channel);
      // const title = decodeURIComponent(parsed.title);
      // $('#phone').text(phone);
      // $('#title').text(title);
      // $('#channelType').text(channel);
      // if (channel == 'viber') {
      //    $('#viber-logo').show();
      // }
      // $('#enrollurl').attr('href', url);
      // qrCode.toCanvas(canvas, url, function (error) {
      //    if (error) console.error(error)
      // })

   }
   else
      if (window.location.href.indexOf('/loginsuccess.html') > 0) {

         Auth.clear();
         const appArea = Tools.getQueryString('state');
         if (appArea && appArea != 'undefined') {
            const url = Tools.removeURLParameter(window.location.href, 'state');
            window.location.assign(url.replace('https://portal2.', `https://${appArea}.portal2.`).replace('https://cloud2.', `https://${appArea}.cloud2.`)) + `?preselect=${appArea}`;
            return;
         }
         if (!Auth.obtainAccessTokenFromUrl()) {
            Auth.authenticateWithCognito();
         }

         const id_token = Auth.obtainIdTokenFromUrl();
         if (!id_token) {
            Auth.authenticateWithCognito();
            return;
         }
         try {
            const id_tokenDecoded = jwtDecode(id_token);
            if (!id_tokenDecoded.appArea) {
               Auth.authenticateWithCognito();
               return;
            }
            $('.login-success-continue-button').click(() => {
               Auth.completeAuthentication();
            })
            if (id_tokenDecoded.appAreaList) {
               const appAreas = id_tokenDecoded.appAreaList.split(';');
               $("#app_arealist").html('');
               appAreas.sort().forEach(a => $("#app_arealist").append(new Option(a, a)))
               $('#app_arealist').val(id_tokenDecoded.appArea);
               if (appAreas.length == 1) {
                  //automatically complete authentication if there is one apparea only
                  Auth.completeAuthentication();
               }
               else {

               }
            }
         }
         catch (ex) {
            Auth.authenticateWithCognito();
            return;
         }
      }
      else if (window.location.href.indexOf('/change-password.htm') > 0) {
         const code = Tools.getQueryString('code');
         const username = Tools.getQueryString('username');
         if (!code || !username) {
            window.location.assign('/index.html');
         }

         $('#btnchpass').on('click', () => {
            $('#error').text('');
            $('.spinner-border').show();
            const password = $('#password').val();
            const confirm = $('#confirm').val();
            if (!password) {
               $('.spinner-border').hide();
               $('#error').text('Please enter password!');
               return;
            }
            if (password != confirm) {
               $('.spinner-border').hide();
               $('#error').text('Password is not confirmed correctly!')
               return;
            }
            RestAPI.changeUserPassword(code, username, password, (r) => {
               $('.spinner-border').hide();
               if (!r || !r.success) {
                  let msg = 'Error while changing password...';
                  if (r.error) {
                     msg = r.error.message ? r.error.message : '';
                  }
                  $('#error').text(msg);
               }
               else {
                  window.location.assign('/index.html');
               }
            });
         })
      }
      else
         if (window.location.href.indexOf('/index.html') > 0 || window.location.href.indexOf('.html') < 0) {

            //test if the url points to root and navigiate to the apparea's specific url
            const url = window.location.href;
            if (url.startsWith('https://portal2.') && url.indexOf('appArea=') > 0) {

               const appArea = Tools.getQueryString('appArea');
               const probingUrl = `${appArea}.portal2.${prodWebsiteDns}`;
               $.ajax(`https://dns.google/resolve?name=${probingUrl}`).promise().then((a, b) => {
                  if (a) {
                     try {
                        const aObj = typeof a == 'string' ? JSON.parse(a) : a;
                        if (aObj.Status == 0) {
                           const newUrl = new URL(url);
                           newUrl.host = `${appArea}.portal2.${prodWebsiteDns}`;
                           window.location.assign(newUrl.toString());
                        }
                     }
                     catch (err) {
                        console.error('ERROR(P32) ', err)
                     }
                  }
               }).catch(err => { console.error('ERROR(P3) ', err) });
            }


            //validate if the domain-apparea and apparea tag are the same
            //and redirect to url where the apprea query string matches the domain apparea
            if (url.indexOf('.portal2.') > 0 && Tools.getQueryString('appArea')) {
               const domainAppArea = url.replace('https://', '').split('.')[0].toLocaleLowerCase();
               let queryAppArea = Tools.getQueryString('appArea');
               queryAppArea = queryAppArea ? queryAppArea.toLocaleLowerCase() : queryAppArea;
               if (domainAppArea != queryAppArea) {
                  const newUrl = Tools.updateQueryStringParameter(url, 'appArea', domainAppArea.toUpperCase());
                  window.location.assign(newUrl);
               }
            }

            //run this on /index.html or of there is no html loaded
            if (!Auth.isAuthenticated()) {
               Auth.authenticateWithCognito();
               return;
            }
            else {
               //authenticated
               if (getPWADisplayMode() == 'browser') {
                  showInstallPromotion();
               }
               else {
                  hideInstallPromotion();
               }
               provisionHostMenus();
               registerServiceWorker();
            }
         }
         else
            if (window.location.href.indexOf('/signup.html') > 0) {

               $('#signUpBtn').on('click', (e) => {
                  e.preventDefault();

                  const email = $('#email').val();
                  const company = $('#company').val();
                  const password = $('#password').val();
                  const phone = $('#phone').val();
                  const termsAndCond = $('#termsAndCond').is(':checked');

                  if (!email) {
                     Swal.fire({
                        title: "Oops",
                        text: "Please enter valid email address",
                        icon: "error"
                     });
                     return;
                  }
                  const userEmailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gm;
                  if (!userEmailRegex.test(email)) {
                     Swal.fire({
                        title: "Oops",
                        text: "Invalid email. Please check your email address",
                        icon: "error"
                     });
                     return;
                  }

                  if (!company) {
                     Swal.fire({
                        title: "Oops",
                        text: "Please enter your company name",
                        icon: "error"
                     });
                     return;
                  }

                  // if(!phone){
                  //    alert('Please enter phone!');
                  //    return;
                  // }

                  // if (!password) {
                  //    Swal.fire({
                  //       title: "Oops",
                  //       text: "Please enter strong password for your account",
                  //       icon: "error"
                  //    });

                  //    return;
                  // }

                  if (!termsAndCond) {
                     Swal.fire({
                        title: "Oops",
                        text: "Please accept terms and conditions to continue",
                        icon: "error"
                     });
                     return;
                  }

                  //start progress
                  $('#signUpBtn').hide();
                  $('#signUpSpinner').show();
                  $.ajax({
                     type: "POST",
                     url: "https://api.portal.dynamicsmobile.com/core/security/register",
                     headers: {
                        "x-api-key": "QCvsyA0m8TBrbvovzT97LycW6eEDtdvsJmlJYZ63"
                     },
                     data: JSON.stringify({
                        "email": email,
                        "company": company,
                        "phone": phone,
                        "termsAndCond": termsAndCond,
                        "defaultApp": 'ADM',
                        "isProd": true,
                        "source": "dms-dynrouting-signup"
                     }),
                     contentType: "application/json",
                     dataType: "json",
                     success: function (data) {
                        //console.log('success', data);
                     },
                     error: function (err) {
                        console.log('err', err);
                     }
                  }).promise().done(() => {
                     $('#emailSuccess').text(email);
                     $('#signUpSpinner').hide();
                     $('#signUpScreen').hide();
                     $('#signUpSuccess').show();
                  }).fail((err) => {
                     $('#signUpBtn').show();
                     $('#signUpSpinner').hide();
                     console.log('err', err);
                     Swal.fire({
                        title: "Oops",
                        text: (err.message ? err.message : (err.responseJSON && err.responseJSON.message ? err.responseJSON.message : 'Error while signing up')),
                        icon: "error"
                     });
                  });
                  return true;
               });

               const email = Tools.getQueryString('email');
               if (email) {
                  $('#email').val(email);
                  $('#company').trigger('focus');
               }
               else {
                  $('#email').trigger('focus');
               }

               // setTimeout(() => {
               //    Swal.fire({
               //       title: "Still having doubts?",
               //       text: "Routing never been easier. Let us show you how! Click Sign Up to get started.",
               //       icon: "success"
               //    });
               // }, 4 * 60 * 1000);
               // $('#enrollurl').click(function (event) {
               //    const checkBox = document.getElementById('consent');
               //    // Check if the element is selected/checked
               //    if (!checkBox.checked) {
               //       event.preventDefault();
               //       alert('You have to agree to receive notifications, first');
               //       $('#consentwrapper').stop().css("background-color", "#FFFF9C").animate({ backgroundColor: "#FFFFFF" }, 1500).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100);
               //       return false;
               //    }
               //    else {
               //       return true;
               //    }
               // });

               // const param = Tools.getQueryString('param');
               // const decoded = atob(param);
               // const parsed = JSON.parse(decoded);
               // const phone = decodeURIComponent(parsed.context);
               // const url = decodeURIComponent(parsed.url);
               // const channel = decodeURIComponent(parsed.channel);
               // const title = decodeURIComponent(parsed.title);
               // $('#phone').text(phone);
               // $('#title').text(title);
               // $('#channelType').text(channel);
               // if (channel == 'viber') {
               //    $('#viber-logo').show();
               // }
               // $('#enrollurl').attr('href', url);
               // qrCode.toCanvas(canvas, url, function (error) {
               //    if (error) console.error(error)
               // })

            }


}

