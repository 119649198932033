
const dms = require('./dms-host');
const $ = require('jquery');
require('jquery-ui');


function basicUIInit() {
	$('.accordion .toggle').click(function (e) {
		e.preventDefault();

		var $this = $(this);

		if ($this.next().hasClass('show')) {
			$this.next().removeClass('show');
			$this.next().slideUp(350);
			$this.removeClass('active');
		} else {
			$this.parent().parent().find('.accordion-header').removeClass('active');
			$this.addClass('active');
			$this.parent().parent().find('.inner').removeClass('show');
			$this.parent().parent().find('.inner').slideUp(350);
			$this.next().toggleClass('show');
			$this.next().slideToggle(350);
		}
	});


	//setup these vars only once since they are static
	var $myIFRAME = $("#hostFrame"),//unless this collection of elements changes over time, you only need to select them once
		windowWidth = 0,//store windowWidth here, this is just a different way to store this data
		resizeTimer = null;

	function setIFrameSize() {

		var magicWidhtNumber = 10;
		var windowWidth = $(window).width();//be aware this will still only get the height of the first element in this set of elements, you'll have to loop over them if you want to support more than one iframe on a page
		var leftSideBarWidth = $('.left-sidebar').width();
		$myIFRAME.attr('width', windowWidth - leftSideBarWidth - magicWidhtNumber);
	}

	$(window).on('resize', function () {
		initPage();
		//only run this once per resize event, if a user drags the window to a different size, this will wait until they finish, then run the resize function
		//this way you don't blow up someone's browser with your resize function running hundreds of times a second
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(function () {
			//make sure to update windowWidth before calling resize function
			windowWidth = $(window).width();

			setIFrameSize();

		}, 75);

	}).trigger("resize");//run this once initially, just a different way to initialize



	$(document).on('click', '.toggleNav', function () {
		$("#main-wrapper").toggleClass('expand-nav');
		$(".extranavlogo").toggleClass('extranavlogoexpanded');
		setTimeout(() => {
			setIFrameSize();
		}, 300);
	});

	$(document).on('mouseenter', '.toggleNav', function () {
		$("#main-wrapper").toggleClass('expand-nav');
		$(".extranavlogo").toggleClass('extranavlogoexpanded');
		setTimeout(() => {
			setIFrameSize();
		}, 300);
	});


	$('.action-nav .action-btn').on('click', function () {
		$(this).toggleClass('active');
		$(".action-menu").toggle("fade");
	});



	$('.quick-menu > a').on('click', function () {
		if ($('.quick-menu').hasClass('open') == false) {
			$('.quick-menu').toggleClass('open');
		}
	});

	$('.quick-menu .close-btn').on('click', function () {
		$('.quick-menu').removeClass('open');
	});

	$('.apps-menu-btn .close-btn').on('click', function () {
		$('.apps-menu-btn').removeClass('open');
	});

	$('.apps-menu-btn > a').on('click', function () {
		$('.apps-menu-btn').addClass('open');
	});
}

//used for testing/dev purposes  - called only in orders-sales-lines.html
function advancedUIInit() {

	return;
	// $('.switch').lc_switch();


	// $('.search-input .search-btn').on('click', function () {
	// 	var searchBox = $(this).parent().find('.search-box'),
	// 		btnOffset = $(this).offset(),
	// 		pageWidth = $(document).width(),
	// 		searchBoxes = $(document).find('.search-input .search-box');

	// 	searchBoxes.each(function (index) {
	// 		$(this).addClass('hide');
	// 	});

	// 	if ((btnOffset.left + searchBox.width()) > pageWidth) {
	// 		searchBox.addClass('left');
	// 	} else {
	// 		searchBox.addClass('right');
	// 	}

	// 	if (searchBox.hasClass('hide')) {
	// 		searchBox.removeClass('hide');
	// 	}
	// });

	// $('#customers-adresses .search-activator').on('click', function () {

	// 	$(this).next('.search').toggleClass('active');
	// });

	// $('.search-input .search-box .close-box').on('click', function () {
	// 	var searchBox = $(this).closest('.search-box').addClass('hide');
	// 	searchBox.removeClass('left, right');
	// });

	// $('.search-input .search-box dd').on('click', function () {
	// 	$(this).closest('.search-input').find('.form-control').val($(this).html());
	// 	var searchBox = $(this).closest('.search-box');
	// 	searchBox.addClass('hide');
	// 	searchBox.removeClass('left, right');
	// });


	// $(document).keypress(function (e) {
	// 	if ((e.keyCode == 70 && e.ctrlKey) || e.keyCode == 114) {
	// 		e.preventDefault();

	// 		var searchBox = $(this).parent().find('.search-box'),
	// 			btnOffset = $(this).offset(),
	// 			pageWidth = $(document).width(),
	// 			searchBoxes = $(document).find('.search-input .search-box');

	// 		searchBoxes.each(function (index) {
	// 			$(this).addClass('hide');
	// 		});

	// 		if ((btnOffset.left + searchBox.width()) > pageWidth) {
	// 			searchBox.addClass('left');
	// 		} else {
	// 			searchBox.addClass('right');
	// 		}

	// 		if (searchBox.hasClass('hide')) {
	// 			searchBox.removeClass('hide');
	// 		}
	// 	}
	// });

	// oTable = $('#myTable').DataTable({
	// 	keys: true
	// });

	// $('#searchInTable').keyup(function () {
	// 	oTable.search($(this).val()).draw();
	// });

	// $('#myTable tbody').on('click', 'tr', function () {
	// 	if ($(this).hasClass('selected')) {
	// 		$(this).removeClass('selected');
	// 		$('#has-actions').removeClass('d-none');
	// 	}
	// 	else {
	// 		oTable.$('tr.selected').removeClass('selected');
	// 		$(this).addClass('selected');

	// 		$('#has-actions').removeClass('d-none');
	// 	}
	// });

	// $('tr').keydown(function (evt) {
	// 	if (evt.keyCode == 40) {
	// 		event.preventDefault();
	// 		var cellindex = $(this).index()
	// 		var rowindex = $(this).parents('tr').index() + 1
	// 		$(this).parents('table').find('tr:eq(' + rowindex + ')').addClass('selected');
	// 	}
	// });

	// $('.dropify').dropify({
	// 	messages: {
	// 		'remove': 'X'
	// 	}
	// });

	// $(document).on('click', '.is-table td', function () {
	// 	selectPurches($(this));
	// 	// console.log('purchase');
	// });

	// $(document).click(function (event) {
	// 	if (!$(event.target).closest(".is-table td, .nav-container").length) {
	// 		deselectPurches();
	// 	}
	// });

	// $(document).on('click', '.modify-purchase-btn', function () {
	// 	if ($("#info-purchase-modal").hasClass('show')) {
	// 		$('#info-purchase-modal').modal('toggle');
	// 	}
	// 	$('#modify-purchase-modal').modal('toggle');
	// });


	// // $(document).on('click', '.dropdown-submenu .back', function() {
	// // 	$('.main-nav').toggleClass('selected-item');
	// // 	$(this).closest('.active').removeClass();
	// // });

	// $('.search-btn').on('click', function () {
	// 	$(this).toggleClass('active');
	// 	$("#search-input").toggleClass('active');
	// 	$("#searchInTable").focus();
	// });

	// $('.dashboard-views .elements, .dashboard-views .close-btn').on('click', function () {
	// 	$(this).closest('.dashboard-views').toggleClass('open');
	// });

	// try {
	// 	$(".custom-select").selectmenu(); 
	// }
	// catch(err){
	// 	//console.warn(err);
	// }

	// $('.task-checkbox').change(function () {
	// 	$(this).closest('.task-row').toggleClass('selected');
	// });

	// $('.new-to-do-btn ').on('click', function () {
	// 	$(this).hide();
	// 	$('.create-to-do-btn').show();
	// 	$('.to-do-form-container').slideToggle();

	// 	return false;
	// });


	// window.addEventListener("keypress", function (event) {
	// 	if ((event.keyCode == 114) || (event.ctrlKey && event.keyCode == 70)) {
	// 		event.preventDefault();

	// 		var searchBox = $(this).parent().find('.search-box'),
	// 			btnOffset = $(this).offset(),
	// 			pageWidth = $(document).width(),
	// 			searchBoxes = $(document).find('.search-input .search-box');

	// 		searchBoxes.each(function (index) {
	// 			$(this).addClass('hide');
	// 		});

	// 		if ((btnOffset.left + searchBox.width()) > pageWidth) {
	// 			searchBox.addClass('left');
	// 		} else {
	// 			searchBox.addClass('right');
	// 		}

	// 		if (searchBox.hasClass('hide')) {
	// 			searchBox.removeClass('hide');
	// 		}
	// 	}
	// })

	// // function keyPresslIsten (e) {
	// // 	let evObj = window.event? event : e;

	// // 	if ((evObj.keyCode == 70 && evObj.ctrlKey) || evObj.keyCode == 114)
	// // 	{
	// // 		e.preventDefault();

	// // 		var searchBox = $(this).parent().find('.search-box'),
	// // 			btnOffset= $(this).offset(),
	// // 			pageWidth = $( document ).width(),
	// // 			searchBoxes = $(document).find('.search-input .search-box');

	// // 		searchBoxes.each(function( index ) {
	// // 		  $(this).addClass('hide');
	// // 		});

	// // 		if ( ( btnOffset.left + searchBox.width() ) > pageWidth ) {
	// // 			searchBox.addClass('left');
	// // 		} else {
	// // 			searchBox.addClass('right');
	// // 		}

	// // 		if (searchBox.hasClass('hide')) {
	// // 			searchBox.removeClass('hide');
	// // 		}
	// // 	}
	// // }

	// // window.addEventListener("keypress", function (e) {
	// // 	if ((e.keyCode == 70 && e.ctrlKey) || e.keyCode == 114)
	// // 	{
	// // 		e.preventDefault();

	// // 		var searchBox = $(this).parent().find('.search-box'),
	// // 			btnOffset= $(this).offset(),
	// // 			pageWidth = $( document ).width(),
	// // 			searchBoxes = $(document).find('.search-input .search-box');

	// // 		searchBoxes.each(function( index ) {
	// // 		  $(this).addClass('hide');
	// // 		});

	// // 		if ( ( btnOffset.left + searchBox.width() ) > pageWidth ) {
	// // 			searchBox.addClass('left');
	// // 		} else {
	// // 			searchBox.addClass('right');
	// // 		}

	// // 		if (searchBox.hasClass('hide')) {
	// // 			searchBox.removeClass('hide');
	// // 		}
	// // 	}
	// // });

	// // document.onkeydown = keyPresslIsten;

	// //$('[data-toggle="tooltip"]').tooltip(); 

	// // $('.dropdown-submenu a').on("click", function(e){
	// // 	$(this).next('ul').toggle();
	// // 	e.stopPropagation();
	// // 	e.preventDefault(); 
	// // });

	// ///$('#ui-id-1').on('change', function (e) {
	// 	// Triggered on change
	// 	// e represents the event
	// 	// $(this) represents the field
	// 	// $(this).val() gets the val
	// //});
}


function selectPurches(thisObj) {
	var rows = $(thisObj).parent().parent().find('tr');
	rows.each(function (index) {
		$(this).removeClass('active');
	});
	$('.nav-container .action-nav.hidden').show();
	$('.nav-container .right-subnav').hide();
	$(thisObj).parent().addClass('active');

	if ($('.nav-container .action-btn').hasClass('active')) {
		$('.nav-container .action-btn').removeClass('active');
		$(".action-menu").fadeOut(100);
	}
}

function deselectPurches() {
	$('.is-table').find('tr').each(function (index) {
		$(this).removeClass('active');
	});
	$('.nav-container .action-nav.hidden, .action-menu').hide();
	$('.nav-container .right-subnav').show();
	$('.nav-container .action-btn').removeClass('active');
}

function initPage() {
	var navBar = $(".topbar").height();
	var windowHeight = $("body").height();


	$('.page-wrapper').css("min-height", (windowHeight - navBar) + "px");
	$('.page-wrapper').css("height", (windowHeight - navBar) + "px");
}


$(document).ready(function () {

	initPage();
	//initialized always
	basicUIInit();

	if (window.location.href.indexOf('/index.html') < 0 && window.location.href.indexOf('/loginsuccess.html') < 0) {
		//e.g. initialize these only in orders-sales-lines.html
		advancedUIInit();
	}

	dms.initApplicationHost();

});


